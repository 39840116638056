// src/App.js
import React, { useState, useEffect } from "react";
import { differenceInBusinessDays, addDays, isWeekend, isWithinInterval, parseISO } from "date-fns";
import './App.css';

const App = () => {
  const graduationDate = parseISO("2025-05-20"); // Updated graduation date: 20th May 2025

  const holidays = [
    [parseISO("2024-09-30"), parseISO("2024-09-30")], // 30th September 2024
    [parseISO("2024-10-28"), parseISO("2024-11-01")], // October mid-term (28th Oct - 1st Nov 2024)
    [parseISO("2024-12-23"), parseISO("2025-01-03")], // Christmas break (23rd Dec - 3rd Jan 2025)
    [parseISO("2025-01-13"), parseISO("2025-01-13")], // 13th January 2025
    [parseISO("2025-02-03"), parseISO("2025-02-03")], // 3rd February 2025
    [parseISO("2025-02-17"), parseISO("2025-02-21")], // February mid-term (17th - 21st Feb 2025)
    [parseISO("2025-03-13"), parseISO("2025-03-13")], // 13th March 2025
    [parseISO("2025-03-17"), parseISO("2025-03-18")], // St. Patrick's Day (17th - 18th March 2025)
    [parseISO("2025-03-25"), parseISO("2025-04-05")], // Easter break (25th March - 5th April 2025)
    [parseISO("2025-05-05"), parseISO("2025-05-05")], // 5th May 2025
  ];

  const isHoliday = (date) => {
    return holidays.some(([start, end]) => isWithinInterval(date, { start, end }));
  };

  const countSchoolDaysLeft = () => {
    const today = new Date();
    let schoolDays = 0;

    for (let date = today; date <= graduationDate; date = addDays(date, 1)) {
      if (!isWeekend(date) && !isHoliday(date)) {
        schoolDays++;
      }
    }

    return schoolDays;
  };


  const [daysLeft, setDaysLeft] = useState(countSchoolDaysLeft);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDaysLeft(countSchoolDaysLeft());
    }, 1000 * 60 * 60 * 24); // Update daily
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <div className="container">
        <h1>Countdown to Leaving Cert Graduation</h1>
        <div className="countdown">{daysLeft} school days left</div>
      </div>
    </div>
  );
};

export default App;
